import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { Link } from 'react-router-dom'
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

const Topbar = ({toggled, setToggled, isCollapsed, setIsCollapsed, broken, setBroken}) => {
    const theme = useTheme();
    const colorMode = useContext(ColorModeContext);

    return (
        <Box display="flex" justifyContent="space-between" p={1}>
            
            <IconButton onClick={() => { 
                if (broken) {
                    setToggled(!toggled)
                    setIsCollapsed(false)
                }
                else {
                    setIsCollapsed(!isCollapsed)
                }
                }}>
                <MenuOutlinedIcon />
            </IconButton>
            
            {/* SEARCH BAR */}
            <Box sx={{ width: "320px" }}>
                {/* <LiveSearch /> */}
            </Box>
            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon />
                    ) : (
                        <LightModeOutlinedIcon />
                    )}
                </IconButton>
                <IconButton component={Link} to="/natification">
                    <NotificationsOutlinedIcon />
                </IconButton>
                <IconButton component={Link} to="/settings">
                    <SettingsOutlinedIcon />
                </IconButton>
                <IconButton component={Link} to="/profile">
                    <PersonOutlinedIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default Topbar;