import { Stack } from "@mui/material";
import {useEffect, useState} from "react";
import SwitchService from "../services/SwitchService";
import CircularProgress from '@mui/material/CircularProgress';
import HostComponent from "../components/Host/HostComponent";

const Host = ({ networkId, hostIp }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [host, setHost] = useState({})
    const [problem, setProblem] = useState([])
    const [hostDownlinks, setHostDownlinks] = useState([])
    const [hostPorts, setHostPorts] = useState([])

    useEffect(() => {
        const fetcher = async () => {
            const responseHost = await SwitchService.getHost(networkId, hostIp);
            setHost(responseHost.data.host)
            const responseZabbixProblems = await SwitchService.getHostProblem(networkId, hostIp)
            setProblem(responseZabbixProblems.data.problems)
            const responseHostDownlinks = await SwitchService.getHostDownlinks(networkId, hostIp)
            setHostDownlinks(responseHostDownlinks.data.hosts)
            const responseHostPorts = await SwitchService.getHostPorts(networkId, hostIp)
            setHostPorts(responseHostPorts.data.ports)
        };
        setIsLoading(false)
        setHost([])
        setProblem([])
        setHostDownlinks([])
        setHostPorts([])
        fetcher()
        setIsLoading(true)
    }, [networkId, hostIp])

    return (
        <Stack
            sx={{
                width: "100%", flexGrow: 1, overflowY: "scroll" 
            }}
        >
            {isLoading ?
                <HostComponent 
                    networkId={networkId}
                    host={host}
                    problem={problem}
                    hostDownlinks={hostDownlinks}
                    hostPorts={hostPorts}
                />
                : <CircularProgress />
            }
        </Stack>
    )
};
export default Host;