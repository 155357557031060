import {useEffect, useState} from "react";
import { Stack } from "@mui/material";
import BillingService from "../services/BillingService";
import DiagnosticDialog from "../components/DiagnosticDialog";
import CircularProgress from '@mui/material/CircularProgress';
import UserComponent from "../components/User/UserComponent"

const User = ({ billingId, userId }) => {

    const [isLoading, setIsLoading] = useState(false)

    const [billingUser, setBillingUser] = useState({})
    const [billingUsersGroups, setBillingUsersGroups] = useState([])
    const [billingUserAccounts, setBillingUserAccounts] = useState([])
    const [billingUserServiceLinks, setBillingUserServiceLinks] = useState([])
    const [billingUserIpServices, setBillingUserIpServices] = useState([])
    const [billingUserSwitchesInfo, setBillingUserSwitchesInfo] = useState([])

    const [openDiagnostic, setOpenDiagnostic] = useState(false);
    const [paramsDiagnostic, setParamsDiagnostic] = useState({});

    const handleOpenDiagnostic = (params) => {
        setParamsDiagnostic(params)
        setOpenDiagnostic(true);
    }

    const handleClose = () => {
        setOpenDiagnostic(false);
    }

    useEffect(() => {
        const fetcher = async () => {
            try {
                const responseBillingUser = await BillingService.billing_user(billingId, userId);
                setBillingUser(responseBillingUser.data)
            } catch (e) {
                console.log(e);
            }
            try {
                const responseBillingUsersGroups = await BillingService.billing_user_groups(billingId, userId);
                setBillingUsersGroups(responseBillingUsersGroups.data)
            } catch (e) {
                console.log(e);
            }
            try {
                const responseBillingUserServiceLinks = await BillingService.billing_user_servicelinks(billingId, userId)
                setBillingUserServiceLinks(responseBillingUserServiceLinks.data)
            } catch (e) {
                console.log(e);
            }
            try {
                const responseBillingUserAccounts = await BillingService.billing_user_accounts(billingId, userId);
                setBillingUserAccounts(responseBillingUserAccounts.data.accounts)
            } catch (e) {
                console.log(e);
            }
            try {
            const responseBillingUserIpServices = await BillingService.billing_user_ip_services(billingId, userId);
            setBillingUserIpServices(responseBillingUserIpServices.data.ip_services)
            setBillingUserSwitchesInfo(responseBillingUserIpServices.data.switches_information)
            } catch (e) {
                console.log(e);
            }
            setIsLoading(true)
        };
        setIsLoading(false)
        setBillingUser([])
        setBillingUsersGroups([])
        setBillingUserServiceLinks([])
        setBillingUserAccounts([])
        setBillingUserIpServices([])
        setBillingUserSwitchesInfo([])
        fetcher()
        
    }, [billingId, userId])

    return (
        <Stack
            sx={{
                width: "100%", flexGrow: 1, overflowY: "scroll" 
            }}
        >
            {/* MODAL Diagnostic */}
            <DiagnosticDialog
                open={openDiagnostic}
                handleClose={handleClose}
                params={paramsDiagnostic}
            >
            </DiagnosticDialog>

            {isLoading ?
                <UserComponent
                    billingId={billingId}
                    handleOpenDiagnostic={handleOpenDiagnostic}
                    billingUser={billingUser}
                    billingUserAccounts={billingUserAccounts}
                    billingUserIpServices={billingUserIpServices}
                    billingUserServiceLinks={billingUserServiceLinks}
                    billingUsersGroups={billingUsersGroups}
                    billingUserSwitchesInfo={billingUserSwitchesInfo}
                />
                : <CircularProgress />
            }
        </Stack>
    )
}
export default User;