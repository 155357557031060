import {Box, Stack,  Typography, useTheme} from "@mui/material";
import { tokens } from "../../theme";
import { useState} from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CircularProgress from '@mui/material/CircularProgress';
import InfoDataGrid from "../../components/InfoDataGrid";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { styled, alpha } from "@mui/material/styles";
import { fTimestamp } from "../../utils/formatTime";
import BillingService from "../../services/BillingService";

const StyledChatBox = styled(Box)(({ theme }) => ({
    "&:hover": {
        cursor: "pointer",
    },
}));

const PersonalAccount = ({ personalAccount, billingId }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [isLoading, setIsLoading] = useState(false)
    const [billingAccountPayments, setBillingAccountPayments] = useState([])

    const columnsPersonalAccountPayments = [
        { field: 'payment_enter_date',
            headerName: 'Payment time',
            flex: 1,
            renderCell: ({ row: { payment_enter_date } }) => {
                return (
                    <Typography>
                        {fTimestamp(payment_enter_date)}
                    </Typography>
                )
            },
            cellClassName: "name-column--cell"
        },
        { field: 'payment_absolute', headerName: 'Sum', flex: 1, },
        { field: 'method', headerName: 'Payment method', flex: 1, },
        { field: 'comments_for_admins', headerName: 'Comment for the administrator', flex: 1, },
        { field: 'comments_for_user', headerName: 'Comment for the user', flex: 1, },
    ];

    const handleChange = () => (event, isExpanded) => {
        if (isExpanded) {
            fetcher()
        }
    };


    const fetcher = async () => {
        if (localStorage.getItem('token')) {
            // setOpenResult(true)
            try {
                setIsLoading(false)
                const responseBillingAccountPayments = await BillingService.billing_account_payments(billingId, personalAccount.account_id)
                setBillingAccountPayments(responseBillingAccountPayments.data)
            } catch (e) {            
                console.log(e);
            }
            setIsLoading(true)
        }
    };

    return (
        <StyledChatBox
            // onClick={() => {
            //     handleAppealClick(id);
            // }}
            sx={{
                width: "100%",

                borderRadius: 1,

                backgroundColor: 0
                    ? theme.palette.mode === "light"
                        ? alpha(theme.palette.primary.main, 0.5)
                        : theme.palette.primary.main
                    : theme.palette.mode === "light"
                        ? "#fff"
                        : theme.palette.background.paper,
            }}
            // p={2}
        >
            <Accordion onChange={handleChange('personalAccounts')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems={"center"}
                        justifyContent="space-between"
                    >
                        <Typography
                            borderRadius={1}
                            p={0.5}
                            backgroundColor={colors.greenAccent[500]}
                        >
                            {personalAccount.account_id}
                        </Typography>
                        <Stack>
                            <Typography
                                color={personalAccount.int_status
                                    ? colors.greenAccent[500]
                                    : colors.redAccent[500]
                                }
                                variant="h5"
                            >
                                {personalAccount.balance} ₽ (Credit: {personalAccount.credit} ₽)
                            </Typography>
                            <Typography color={personalAccount.block_id === 0
                                    ? colors.greenAccent[500]
                                    : colors.redAccent[500]
                                }
                                variant="h5"
                            >
                                {personalAccount.block_type === null
                                    ? "Активен"
                                    : personalAccount.block_type === 1
                                        ? "Системная блокировка"
                                        : personalAccount.block_type === 2
                                            ? "Административная блокировка"
                                            : personalAccount.block_type === 2
                                                ? "Пользовательская блокировка"
                                                : "Unknown"
                                }               
                            </Typography>
                        </Stack>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    {isLoading
                        ?   <InfoDataGrid
                                columns={columnsPersonalAccountPayments}
                                rows={billingAccountPayments}
                                // getRowId={getRowId()}
                            />
                        : <CircularProgress />
                    }
                </AccordionDetails>
            </Accordion>
        </StyledChatBox>
    );
};

export default PersonalAccount;