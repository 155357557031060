import {
    Box,
    Typography,
    useTheme,
    Divider,
    Stack,
    IconButton
} from "@mui/material";
import { styled } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import InfoDataGrid from "../../components/InfoDataGrid";
import InfoBox from "../InfoBox";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import GroupsIcon from '@mui/icons-material/Groups';
import CommentIcon from '@mui/icons-material/Comment';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import BuildIcon from '@mui/icons-material/Build';
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';

import { tokens } from "../../theme";
import { int2ip } from "../../utils/formatIpAddress"
import { fTimestamp } from "../../utils/formatTime";
import PersonalAccount from "./PersonalAccount";
import ServiceLinks from "./ServiceLinks";

const UserComponent = ({ 
    billingId, 
    handleOpenDiagnostic, 
    billingUser,
    billingUsersGroups, 
    billingUserAccounts,
    billingUserServiceLinks,
    billingUserIpServices,
    billingUserSwitchesInfo
}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const columnsIpServices = [
        {
            field: 'ip',
            headerName: 'IP',
            flex: 1,
            renderCell: ({ row: { ip, service_name, comment } }) => {
                return (
                    <Box>
                        <Typography>
                            {ip}
                        </Typography>
                        <Typography>
                            { service_name }({ comment })
                        </Typography>
                    </Box>
                )
            }
        },
        { field: 'speed', headerName: 'Speed' },
        { field: 'mac', headerName: 'Mac' },
    ];

    const columnsSwitchesInfo = [
        {
            field: 'arp_entry',
            headerName: 'Arp entry',
            flex: 1,
            renderCell: ({ row: { arp_ip, arp_time , gateway} }) => {
                return (
                    <Box>
                        <Typography>
                            { int2ip(arp_ip) }
                        </Typography>
                        <Typography>
                            { int2ip(gateway) }({ arp_time })
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: 'fdb_entry',
            headerName: 'Fdb entry',
            flex: 1,
            renderCell: ({row: {mac, fdb_time} }) => {
                return (
                    <Box>
                        <Typography>
                            {mac}
                        </Typography>
                        <Typography>
                            {fdb_time}
                        </Typography>
                    </Box>
                )
            }
        },
        {
            field: 'switch',
            headerName: 'Switch',
            flex: 1,
            renderCell: ({row: {sw_ip, sw_port}}) => {
                return (
                    <Box>{ int2ip(sw_ip) }:{sw_port}</Box>
                )
            }
        },
        {
            field: 'diagnostic',
            headerName: 'Diagnostic',
            renderCell: ({row: {fdb_network, sw_ip, sw_port, arp_network, arp_ip, gateway}}) => {
                return (
                    <Box>
                        <IconButton onClick={() => {handleOpenDiagnostic({
                            "fdb_network": fdb_network,
                            "sw_ip": sw_ip,
                            "sw_port": sw_port,
                            "arp_network": arp_network,
                            "arp_ip" : arp_ip,
                            "gateway": gateway
                        })}} aria-label="delete" size="large">
                            <SettingsOutlinedIcon/>
                        </IconButton>
                    </Box>
                )
            }
        },
    ];

    const StyledChatBox = styled(Box)(({ theme }) => ({
        "&:hover": {
            cursor: "pointer",
        },
    }));

    return (
        <StyledChatBox
            sx={{
                width: "100%",
                borderRadius: 1,
                // backgroundColor: id == selectedChatId
                //     ? theme.palette.mode === "light"
                //         ? alpha(theme.palette.primary.main, 0.5)
                //         : theme.palette.primary.main
                //     : theme.palette.mode === "light"
                //         ? "#fff"
                //         : theme.palette.background.paper,
            }}
            p={2}
        >   
            <Stack
                direction="row"
                alignItems={"center"}
                justifyContent="space-between"
            >
                <Stack direction="row" spacing={2}>
                    {" "}
                    
                    {/* <AccountCircleIcon /> */}
                    
                    <Stack spacing={0.3}>
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            sx={{ color: colors.grey[100] }}
                        >
                            ID: {billingUser.id}
                        </Typography>
                        <Typography variant="caption">
                            Login: {billingUser.login}
                        </Typography>
                        <Typography variant="caption">
                            {billingUser.full_name}
                        </Typography>
                        
                    </Stack>
                </Stack>
                <Stack alignItems={"top"}>
                    <Typography sx={{ fontWeight: 600 }} variant="caption">
                        Created:
                    </Typography>
                    <Typography sx={{ fontWeight: 600 }} variant="caption">
                        {fTimestamp(billingUser.create_date)}
                    </Typography>
                    {/* <Badge
                        className="unread-count"
                        color="primary"
                        badgeContent="changed"
                    /> */}
                </Stack>
            </Stack>
            <Divider />
            <Stack
                direction="row"
                spacing={{ xs: 1, sm: 2 }}
                useFlexGap
                sx={{ flexWrap: 'wrap', alignItems: "flex-end" }}
            >
                <InfoBox title={"Location"} icon={<LocationOnIcon />} info={billingUser.actual_address} />
                <InfoBox title={"E-mail"} icon={<EmailIcon />} info={billingUser.email} />
                <InfoBox title={"Mobile telephone"} icon={<PhoneIcon />} info={billingUser.mobile_telephone} />
                <InfoBox title={"Home telephone"} icon={<PhoneIcon />} info={billingUser.home_telephone} />
                <InfoBox title={"Work telephone"} icon={<PhoneIcon />} info={billingUser.work_telephone} />
                <Divider />
            </Stack>
            <Divider />
            <InfoBox title={"Comments:"} icon={<CommentIcon />} info={billingUser.comments} />
            <Divider />
            <Stack m={"10px"} spacing={0.5}>
                <GroupsIcon />
                {/* <Typography variant="h5">Groups:</Typography> */}
                <Stack 
                    direction="row"
                    spacing={{ xs: 1, sm: 2 }}
                    useFlexGap
                    sx={{ flexWrap: 'wrap' }}

                >
                    {billingUsersGroups.length === 0 
                        ? <Typography variant="h5">No groups</Typography>
                        : billingUsersGroups.map((el) => {
                            return <Typography 
                                    borderRadius={1}
                                    p={0.2}
                                    backgroundColor={ colors.greenAccent[600] }
                                    >
                                        {el.group_name}
                                    </Typography>;
                        })
                    }
                </Stack>
            </Stack>
            <Divider />
            <Stack m={"10px"} spacing={0.5}>
                <Accordion expanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <CurrencyRubleIcon /> 
                        <Typography color={colors.greenAccent[500]} variant="h5">
                            Personal accounts
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {billingUserAccounts.map((el) => {
                            return  <PersonalAccount
                                        personalAccount={el}
                                        billingId={billingId}
                                    />;
                        })}
                    </AccordionDetails>
                </Accordion>
            </Stack>
            <Divider />
            <Stack m={"10px"}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <ElectricalServicesIcon />
                        <Typography color={colors.greenAccent[500]} variant="h5">
                            Service link
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {billingUserServiceLinks.map((el) => {
                            return <ServiceLinks serviceLink={el} />;
                        })}
                    </AccordionDetails>
                </Accordion>
                
            </Stack>
            <Divider />
            <Stack m={"10px"}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <BuildIcon />
                        <Typography color={colors.greenAccent[500]} variant="h5">
                            Technical information
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InfoDataGrid
                            columns={columnsIpServices}
                            rows={billingUserIpServices}
                        />
                        <InfoDataGrid
                            columns={columnsSwitchesInfo}
                            rows={billingUserSwitchesInfo}
                        />
                    </AccordionDetails>
                </Accordion>
            </Stack>
        </StyledChatBox>
    );
};

export default UserComponent;