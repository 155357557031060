import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Topbar from "./scenes/global/Topbar";
import AppSidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Billings from "./scenes/billings";
import Switches from "./scenes/switches";
import Appeals from "./scenes/appeals";
import GlobalSearch from "./scenes/globalSearch";
import MainReportBilling from "./scenes/main_report_billing";
import Profile from "./scenes/profile";
import Natification from "./scenes/natification";
import Settings from "./scenes/settings";

function App() {
    const {store} = useContext(Context);
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);
    const [broken, setBroken] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('token')) {
            store.checkAuth()
        }   
    }, [ store ])


    if (store.isLoading) {
        return <div>Загрузка...</div>
    }

    if (!store.isAuth) {
        return (
            <div>
                <LoginForm/>
            </div>
        );
    }

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <div className="app">
                    <AppSidebar isSidebar={isSidebar} toggled={toggled} setToggled={setToggled} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} broken={broken} setBroken={setBroken}/>
                    <main className="content">
                        <Topbar setIsSidebar={setIsSidebar} toggled={toggled} setToggled={setToggled} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} broken={broken} setBroken={setBroken}/>
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/billings" element={<Billings />} />
                            <Route path="/switches" element={<Switches />} />
                            <Route path="/appeals" element={<Appeals/>} />
                            <Route path="/search" element={<GlobalSearch />} />
                            <Route path="/search/:object/:source/:id" element={<GlobalSearch />} />
                            <Route path="/main_report_billing" element={<MainReportBilling />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/natification" element={<Natification />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/logout" element={<LoginForm />} action={() => store.logout()} />
                        </Routes>
                    </main>
                </div>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
}

export default observer(App);
